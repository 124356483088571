import { Injector, NgModule } from '@angular/core';

import './relation-resource-patch/relation-resource.patch';

console.log('RelationResource patch loaded');
@NgModule({})

export class PluginModule {
  constructor(injector: Injector) { }
}
