import { RelationResource } from 'core-app/features/hal/resources/relation-resource';

const originalRelationTypes = RelationResource.RELATION_TYPES;

RelationResource.RELATION_TYPES = function (includeParentChild = true): string[] {
  const types = originalRelationTypes.call(this, includeParentChild);

  const typesStartFinish = ['start_precedes', 'start_follows', 'finish_precedes', 'finish_follows'];
  types.push(...typesStartFinish);

  return types;
};
